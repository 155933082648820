import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
interface FilesState {
  fileGroups: string[];
  loading: boolean;
  error: boolean;
}
const initialState: FilesState = {
  fileGroups: [],
  loading: false,
  error: false,
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    updateLoading: (state, action) => {},
    updateError: (state, action) => {},
    retrieveFiles: (state, action) => {},
    uploadFile: (state, action) => {},
    deleteFile: (state, action) => {},
  },
});

export const {
  uploadFile,
  retrieveFiles,
  updateError,
  updateLoading,
  deleteFile,
} = filesSlice.actions;
export const filesSelector = (state: RootState) => state.files.fileGroups;
export const loadingSelector = (state: RootState) => state.files.loading;
export const errorSelector = (state: RootState) => state.files.error;

export default filesSlice.reducer;
