import { useEffect, useState } from 'react';
import {
  ChartRankButtonsProps,
  rankButtons,
  RankButtonType,
  rankYearButtons,
} from './ChartRankButtons.model';

function ChartRankButtons({
  activeChartButton,
  setActiveChartButton,
  rangeType = 'Rank',
}: ChartRankButtonsProps) {
  const [buttonsToUse, setButtonsToUse] = useState<RankButtonType[]>([]);

  useEffect(() => {
    switch (rangeType) {
      case 'Rank': {
        setButtonsToUse(rankButtons);
        break;
      }
      case 'Year': {
        setButtonsToUse(rankYearButtons);
        break;
      }
    }
  }, [rangeType]);

  return (
    <div className="mt-3 flex justify-center gap-1">
      {buttonsToUse.map((type, index) => {
        const titleSplit = type.split('');
        if (type.includes('Rank')) {
          titleSplit.splice(titleSplit.length - 1, 0, ' ');
        }
        return (
          <button
            key={`button-${index}`}
            onClick={() => setActiveChartButton && setActiveChartButton(type)}
            className={`w-36 border-b  bg-marble px-6 py-3 ${
              activeChartButton === type
                ? 'border-b-topaz-500 bg-topaz-200 '
                : 'border-b-gray-500'
            }`}
          >
            {titleSplit.join('')}
          </button>
        );
      })}
    </div>
  );
}

export default ChartRankButtons;
