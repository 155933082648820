import { DeepPartial } from '@reduxjs/toolkit';
import {
  BubbleDataPoint,
  ChartDatasetProperties,
  ChartType,
  ChartTypeRegistry,
  DefaultDataPoint,
  ScatterDataPoint,
} from 'chart.js';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

interface Data {
  answer: string;
  average?: number;
  count: number;
  year: number;
}

interface IChartData {
  Answer: string;
  PreviousYear: number;
  ThisYear: number;
}

interface IDatasets {
  previousYearTotal: number;
  thisYearTotal: number;
  datasets: ChartDatasetExtended<
    'bar',
    (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[]
  >[];
  labels: string[];
}

export type ChartDatasetExtended<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>
> = DeepPartial<
  {
    [key in ChartType]: {
      type: key;
    } & ChartTypeRegistry[key]['datasetOptions'];
  }[TType]
> &
  ChartDatasetProperties<TType, TData> & {
    countData: any;
  };

export function getDatasets(
  data: Data[],
  previousYearFilter: boolean,
  thisYearFilter: boolean
): IDatasets {
  // Group Data
  let groupedData: IChartData[] = [];
  for (const d of data) {
    // Check if answer already exists in the data
    let answer = groupedData.find((x) => x.Answer === d.answer);
    if (!answer) {
      answer = {
        Answer: d.answer,
        PreviousYear: 0,
        ThisYear: 0,
      };
      groupedData.push(answer);
    }

    if (d.year === surveyYear - 1) {
      answer.PreviousYear = d.count;
    }
    if (d.year === surveyYear) {
      answer.ThisYear = d.count;
    }
  }

  // Sort Data
  let sortedData = [...groupedData].sort((prev, next) => {
    // Order by this year Data
    if (prev.ThisYear < next.ThisYear) return 1;
    if (prev.ThisYear > next.ThisYear) return -1;

    // Then order by previous year Data
    if (prev.PreviousYear < next.PreviousYear) return 1;
    if (prev.PreviousYear > next.PreviousYear) return -1;
    return 0;
  });

  // Get totals for year
  const previousYearTotal = sortedData.reduce(
    (acc, result) => acc + result.PreviousYear,
    0
  );
  const thisYearTotal = sortedData.reduce(
    (acc, result) => acc + result.ThisYear,
    0
  );

  let datasets: ChartDatasetExtended<
    'bar',
    (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[]
  >[] = [];

  // Only add data if filter checked
  if (previousYearFilter && previousYearTotal > 0) {
    const previousYearData = sortedData.map((result) =>
      Math.round((result.PreviousYear / previousYearTotal) * 100)
    );
    const previousYearCountData = sortedData.map(
      (result) => result.PreviousYear
    );
    if (previousYearData.length > 0) {
      datasets.push({
        data: previousYearData,
        countData: previousYearCountData,
        label: '% of respondents',
        backgroundColor: ['#2B9AB3'],
      });
    }
  }

  // Only add data if filter checked
  if (thisYearFilter && thisYearTotal > 0) {
    const thisYearData = sortedData.map((result) =>
      Math.round((result.ThisYear / thisYearTotal) * 100)
    );
    const thisYearCountData = sortedData.map((result) => result.ThisYear);
    if (thisYearData.length > 0) {
      datasets.push({
        data: thisYearData,
        countData: thisYearCountData,
        label: '% of respondents',
        backgroundColor: ['#E26E00'],
      });
    }
  }

  const labels = sortedData.map((item) => {
    return item.Answer;
  });

  return {
    previousYearTotal,
    thisYearTotal,
    datasets,
    labels,
  };
}
