import { triggerFileDownload } from '../../helpers/triggerFileDownload/triggerFileDownload';
import { api } from '../axiosInstance';
import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';

export const getCSVExportCompletesByChannel = async (
  params: SurveyFilters,
  token: string
) => {
  new Promise((resolve, reject) => {
    // Make the request
    api
      .get<File>('SurveyCompletesByChannelCsv', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // Set Prefix
        const currentDate = new Date();
        const datePrefix = `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()}`;

        // Download and resolve.
        triggerFileDownload(
          res,
          `SurveyExport_CompletesByChannel_${datePrefix}`
        );
        resolve(res.data);
      })
      .catch((error) => {
        // Alert and reject.
        alert('Failed to download CSV export, please try again later.');
        reject(error);
      });
  });
};
