import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';
import { api } from './../axiosInstance';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

interface SurveyCompletesByDateResponse {
  date: Date;
  year: number;
  status: 'Complete' | 'Abandoned';
  count: number;
}

export interface SurveyCompletesByDate {
  date: Date;
  complete: number;
  abandoned: number;
  year: number;
}
export const getSurveyCompletesByDate = async (
  params: SurveyFilters,
  token: string
) => {
  const res = await api.get<SurveyCompletesByDateResponse[]>(
    'SurveyCompletesByDate',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // Get unique sources
  const dates = Array.from(new Set(res.data.map((item) => item.date))).map(
    (date) => date
  );

  return getYearSourceData(res.data, dates, surveyYear - 1).concat(
    getYearSourceData(res.data, dates, surveyYear)
  );
};

function getYearSourceData(
  data: SurveyCompletesByDateResponse[],
  dates: Date[],
  year: number
) {
  return dates.map((date) => {
    const dateEntries = data.filter(
      (item) => item.date === date && item.year === year
    );
    const complete = dateEntries.find((item) => item.status === 'Complete');
    const abandoned = dateEntries.find((item) => item.status === 'Abandoned');

    return {
      date,
      complete: complete ? complete.count : 0,
      abandoned: abandoned ? abandoned.count : 0,
      year,
    };
  });
}
