import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';
import { api } from './../axiosInstance';

export interface SurveyCompletesByAge {
  age: string;
  count: number;
  year: number;
}

export const getSurveyCompletesByAge = async (
  params: SurveyFilters,
  token: string
) => {
  const res = await api.get<SurveyCompletesByAge[]>('SurveyCompletesByAge', {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
