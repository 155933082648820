// Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

// Components
import App from './App';
import store from './store';

// Assets
import './index.css';
import './assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-3.woff';
import './assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-5.woff2';
import './assets/fonts/Amplitude/amplitude-light.woff';
import './assets/fonts/Amplitude/amplitude-light.woff2';
import './assets/fonts/Amplitude/amplitude-medium.woff';
import './assets/fonts/Amplitude/amplitude-regular.woff';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      redirectUri={window.location.origin}
      domain={process.env.REACT_APP_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
