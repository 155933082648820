// Dependencies
import React from 'react';

// Components
import { Checkbox } from '../Checkbox/Checkbox';

// Models
import { CheckboxListProps } from './CheckboxList.model';

/**
 * Checkbox List Component
 */
export const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  columns,
}) => {
  return (
    <div className={columns ? `columns-${columns}` : ''}>
      {items.map((item, index) => {
        return (
          <div className={`block w-full ${index > 0 && 'mt-3'}`} key={index}>
            <Checkbox {...item} />
          </div>
        );
      })}
    </div>
  );
};
