import React from 'react';
import { PageContainerProps } from './PageContainer.model';

function PageContainer({
  title,
  button,
  children,
  bgColor = 'bg-white',
  summaryResults,
  filterBar,
}: PageContainerProps) {
  return (
    <div className="relative">
      <div className="flex items-center justify-between px-4 py-4 lg:py-8 lg:px-10">
        <h1 className=" text-xl font-medium text-brown-500">{title}</h1>
        {button && button}
      </div>
      {summaryResults && (
        <div className="px-4 pb-4 lg:px-10">{summaryResults}</div>
      )}
      {filterBar && <div className="sticky top-0 z-10">{filterBar}</div>}
      <main className={`min-h-screen space-y-6 ${bgColor}`}>{children}</main>
    </div>
  );
}

export default PageContainer;
