import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { EditIcon } from '../Icons/Icons';
import { useAuth0 } from '@auth0/auth0-react';
import { triggerPasswordChangeFlow } from '../../../services/auth0Api';
import { Paragraph } from '../Paragraph/Paragraph';

function EditUser() {
  const { user } = useAuth0();
  const [passwordResetSent, setPasswordResetSent] = useState<boolean>(false);
  const [passwordResetError, setPasswordResetError] = useState<boolean>(false);

  const startPasswordReset = async (email: string | undefined) => {
    if (email) {
      setPasswordResetError(false);
      if (await triggerPasswordChangeFlow(email)) {
        setPasswordResetSent(true);
      } else {
        setPasswordResetError(true);
      }
    }
  };

  return (
    <section>
      <h2 className="text-xl text-brown-500">Change Password</h2>
      <Paragraph>
        Click change password, and we will send you instructions to reset your
        password.
      </Paragraph>
      {user?.email && (
        <Button
          onClick={() => {
            startPasswordReset(user?.email);
          }}
          type="button"
          variant="primary"
          className="flex h-11 items-center gap-1 md:mt-8"
        >
          <EditIcon /> Change Password
        </Button>
      )}
      {passwordResetError && (
        <Paragraph className="pt-5 pb-5">
          We're sorry there was a problem sending your password reset email.
        </Paragraph>
      )}
      {passwordResetSent && (
        <Paragraph className="pt-5 pb-5">
          We've just sent you an email to reset your password.
        </Paragraph>
      )}
    </section>
  );
}

export default EditUser;
