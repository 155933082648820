import React, { useRef } from 'react';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import { SurveyDistributionTableProps } from './SurveyDistributionTable.model';
import { exportChartToSvg } from '../../../helpers/triggerFileDownload/triggerFileDownload';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

function SurveyDistributionTable({
  data,
  SVGExportName,
  onCSVDownload,
}: SurveyDistributionTableProps) {
  const chartRef = useRef<null | HTMLTableElement>(null);
  const sources = Array.from(
    new Set(data.flatMap((r) => r.sources).map((s) => s.source))
  ) as string[];

  const dataFilteredAndSorted = data
    .filter(({ country }) => country)
    .sort((a, b) =>
      a.sources.reduce((prev, current) => {
        return prev + current.count;
      }, 0) <
      b.sources.reduce((prev, current) => {
        return prev + current.count;
      }, 0)
        ? 1
        : -1
    );

  return (
    <div className={`'pb-0' p-4 lg:px-12 lg:pt-8`}>
      <div className="w-full overflow-hidden bg-limestone lg:py-8">
        <div className="flex w-full flex-row flex-wrap items-start justify-between">
          <div className="w-full max-w-475">
            <Heading level="h4" className="mb-2">
              Survey completions by country {surveyYear}
            </Heading>
          </div>
          <div className="flex w-full gap-x-4 lg:w-auto">
            {SVGExportName && (
              <Button
                variant="secondary"
                className="mb-6 w-1/2 lg:w-auto xl:mb-0"
                type="button"
                onClick={() => {
                  exportChartToSvg(SVGExportName || 'chart', chartRef);
                }}
              >
                Download SVG
              </Button>
            )}
            {onCSVDownload && (
              <Button
                variant="standard"
                className="mb-6 w-1/2 lg:w-auto xl:mb-0"
                type="button"
                onClick={onCSVDownload}
              >
                Download CSV
              </Button>
            )}
          </div>
        </div>

        <div className="mt-6 w-full max-w-full overflow-hidden">
          <div className="relative max-h-[85vh] w-auto max-w-full overflow-auto">
            <table
              ref={chartRef}
              className="w-full table-auto text-center font-normal text-gray-500"
            >
              <thead className="sticky left-0 top-0 bg-gray-50 uppercase text-white">
                <tr>
                  <th scope="col" className="bg-limestone"></th>

                  {dataFilteredAndSorted.map((countryEntry) => {
                    const country = countryEntry.country;

                    return (
                      <th
                        key={country}
                        scope="col"
                        className="border border-titanium bg-blue-900 py-6 px-6"
                      >
                        {country}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-titanium">
                <tr className="border-b bg-white">
                  <td className="min-w-[140px] whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                    Total
                  </td>
                  {dataFilteredAndSorted.map((countryEntry) => {
                    const completes = countryEntry.sources.reduce(
                      (prev, current) => {
                        return prev + current.count;
                      },
                      0
                    );
                    return <td key={countryEntry.country}>{completes}</td>;
                  })}
                </tr>

                {sources.map((source, index) => {
                  return (
                    <tr className="border-b bg-white" key={source}>
                      <td className="min-w-[140px] whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {source}
                      </td>

                      {dataFilteredAndSorted.map((countryEntry, cIindex) => {
                        const country = countryEntry.country;
                        const relatedItems = countryEntry.sources.filter(
                          (entry) =>
                            entry.source === source &&
                            entry.status === 'Complete'
                        );

                        return (
                          <td
                            className="border border-titanium py-4 px-6"
                            key={country}
                          >
                            {relatedItems.reduce((prev, current) => {
                              return prev + current.count;
                            }, 0)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyDistributionTable;
