import React, { useEffect, useState } from 'react';
import { getWindowWidth } from '../../../helpers/getWindowWidth/getWindowWidth';
import { CloseIcon, HamburgerIcon } from '../../Atoms/Icons/Icons';
import JPMMainLogo from '../../Atoms/JPMLogo/JPMMainLogo';
import SidebarItems from './SidebarItems/SidebarItems';

function Sidebar() {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const closeNav = () => {
    setMobileMenuActive(false);
  };

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', mobileMenuActive);
    document.body.classList.toggle('fixed', mobileMenuActive);
    document.body.classList.toggle('w-full', mobileMenuActive);
    const resizeListener = () => {
      if (getWindowWidth() >= 1024 && mobileMenuActive) {
        setMobileMenuActive(false);
        document.body.classList.remove('overflow-hidden', 'fixed', 'w-full');
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [mobileMenuActive]);

  return (
    <div
      className={`relative w-full bg-white px-4 lg:z-0 lg:max-w-[300px] lg:border-r-2 lg:pl-10 lg:pr-7 ${
        mobileMenuActive ? ' h-screen' : ''
      }`}
    >
      <div className="sticky top-0 z-10 ">
        <div
          className={`${
            mobileMenuActive ? '' : 'border-b border-b-jpm-brown'
          } flex items-center justify-between pb-2
      lg:border-b-0`}
        >
          <JPMMainLogo topBorder />
          <div className="lg:hidden">
            {mobileMenuActive ? (
              <button onClick={() => setMobileMenuActive(false)}>
                <CloseIcon />
              </button>
            ) : (
              <button onClick={() => setMobileMenuActive(true)}>
                <HamburgerIcon />
              </button>
            )}
          </div>
        </div>
        <SidebarItems mobileMenuActive={mobileMenuActive} closeNav={closeNav} />
      </div>
    </div>
  );
}

export default Sidebar;
