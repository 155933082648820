import React from 'react';
import { Button } from '../../Atoms/Button/Button';
import { CloseIcon, RemoveIcon } from '../../Atoms/Icons/Icons';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import Modal from '../../Molecules/Modal/Modal';
import { RemoveModalProps } from './RemoveModal.model';

function RemoveModal({
  isRemoveModalOpen,
  toggleRemoveModal,
  description,
  title,
  onRemoveSubmit,
}: RemoveModalProps) {
  return (
    <Modal
      isOpen={isRemoveModalOpen}
      title={title}
      toggleModal={toggleRemoveModal}
    >
      <Paragraph className="mb-6">{description}</Paragraph>
      <div className="flex items-center justify-center gap-4">
        <Button
          onClick={toggleRemoveModal}
          type="button"
          variant="secondary"
          className="flex items-center gap-3"
        >
          <CloseIcon fill="#1A7B99" className="h-auto w-3" />
          Cancel
        </Button>
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
            if (onRemoveSubmit) {
              onRemoveSubmit();
            }
            toggleRemoveModal();
          }}
        >
          <Button
            type="submit"
            variant="primary"
            className="flex items-center gap-3"
          >
            <RemoveIcon fill="white" className="h-auto w-3" />
            Remove
          </Button>
        </form>
      </div>
    </Modal>
  );
}

export default RemoveModal;
