import React from 'react';
import { Paragraph } from '../Paragraph/Paragraph';
import { JPMLogo } from '../Icons/Icons';
import { JPMMainLogoProps } from './JPMMainLogo.model';
function JPMMainLogo({ topBorder }: JPMMainLogoProps) {
  return (
    <div>
      <JPMLogo
        className={`w-44 ${
          topBorder ? 'border-t-4 border-jpm-brown pt-6 lg:border-t-6' : ''
        }  fill-jpm-brown  `}
      />
      <Paragraph className=" pt-1 text-brown-500">The e-Trading Edit</Paragraph>
    </div>
  );
}

export default JPMMainLogo;
