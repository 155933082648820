import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';
import { api } from './../axiosInstance';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

interface SurveyCompletesByDistributionChannelResponse {
  source: string;
  status: 'Complete' | 'Abandoned';
  count: number;
  year: number;
}

export interface SurveyCompletesByDistributionChannel {
  source: string;
  complete: number;
  abandoned: number;
  year: number;
}

export const getSurveyCompletesByDistributionChannel = async (
  params: SurveyFilters,
  token: string
): Promise<SurveyCompletesByDistributionChannel[]> => {
  const res = await api.get<SurveyCompletesByDistributionChannelResponse[]>(
    'SurveyCompletesByChannel',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // Get unique sources
  const sources = Array.from(new Set(res.data.map((item) => item.source))).map(
    (source) => source
  );

  return getYearSourceData(res.data, sources, surveyYear - 1).concat(
    getYearSourceData(res.data, sources, surveyYear)
  );
};

function getYearSourceData(
  data: SurveyCompletesByDistributionChannelResponse[],
  sources: string[],
  year: number
) {
  return sources.map((source) => {
    const sourceEntries = data.filter(
      (item) => item.source === source && item.year === year
    );
    const complete = sourceEntries.find((item) => item.status === 'Complete');
    const abandoned = sourceEntries.find((item) => item.status === 'Abandoned');

    return {
      source,
      complete: complete ? complete.count : 0,
      abandoned: abandoned ? abandoned.count : 0,
      year,
    };
  });
}
