import React from 'react';
import { CSVEXportIcon, CSVIcon } from '../Icons/Icons';
import { ExportCSVItemProps } from './ExportCSVItem.model';

function ExportCSVItem({ fileName, onClick }: ExportCSVItemProps) {
  return (
    <button
      className="flex cursor-pointer items-center justify-between rounded-md border border-titanium bg-marble py-4 px-5"
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <CSVIcon />
        <p className="font-medium">{fileName}</p>
      </div>
      <CSVEXportIcon />
    </button>
  );
}

export default ExportCSVItem;
