// Dependencies
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterController from './components/Containers/MainContainer/Routes/Routes';
import Layout from './components/Organisms/Layout/Layout';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <RouterController />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
