// Dependencies
import { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiSortVariant } from '@mdi/js';

// Components
import { Filter } from '../../Molecules/Filter/Filter';

// Models
import { FilterBarProps, FilterSection } from './FilterBar.model';

// Services
import { getSurveyFilters } from '../../../services/getSurveyFilters/getSurveyFilters';
import { useAuth0 } from '@auth0/auth0-react';

const productFilterLabels = {
  TradeFX: 'FX',
  TradeFXAndPreciousMetalsOptions: 'FX & Precious Metals Options',
  TradeG10Rates: 'G10 rates',
  TradeEMRates: 'EM rates',
  TradeCreditSpread: 'Credit / Spread',
  TradeCommodities: 'Commodities (including Precious Metals)',
  TradeFuturesAndOptions: 'Futures & Options',
  TradeCashEquities: 'Cash Equities',
  TradeEquityDerivatives: 'Equity Derivatives',
  TradeCryptoDigitalCoins: 'Crypto/digital coins',
  TradeOther: 'Other',
};

const FilterBar: React.FC<FilterBarProps> = ({ onChange }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [filterSectionOpen, setFilterSectionOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<string>();
  const [filters, setFilters] = useState<FilterSection[]>([]);
  const wrapperRef = useRef<any>();

  const setupFilters = (
    ageValues: string[],
    productValues: string[],
    sourceValues: string[]
  ) => {
    setFilters([
      {
        id: 'age',
        label: 'Age',
        options: ageValues.map((value) => {
          return { value };
        }),
        multiple: true,
      },
      {
        id: 'product-traded',
        label: 'Product Traded',
        options: productValues.map((value) => {
          return {
            value,
            label:
              productFilterLabels[value as keyof typeof productFilterLabels],
          };
        }),
        multiple: true,
      },
      {
        id: 'source',
        label: 'Source',
        options: sourceValues.map((value) => {
          return {
            label: value,
            value,
          };
        }),
        multiple: true,
      },
      {
        id: 'country',
        label: 'Country',
        options: [
          {
            label: 'United States',
            value: 'United States',
          },
          {
            label: 'United Kingdom',
            value: 'United Kingdom',
          },
          {
            label: 'Rest of North America',
            value: 'Rest of North America',
          },
          {
            label: 'Rest of Europe',
            value: 'Rest of Europe',
          },
          {
            label: 'Asia',
            value: 'Asia',
          },
          {
            label: 'South America',
            value: 'South America',
          },
          {
            label: 'Africa',
            value: 'Africa',
          },
          {
            label: 'Oceania',
            value: 'Oceania',
          },
          {
            label: 'Other',
            value: 'Other',
          },
        ],
        multiple: true,
      },
    ]);
  };

  useEffect(() => {
    setupFilters([], [], []);

    const makeRequestsAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        getSurveyFilters(accessToken)
          .then(({ ageValues, productValues, sourceValues }) => {
            setupFilters(ageValues, productValues, sourceValues);
          })
          .catch(console.error);
      } catch (e) {
        alert('Authentication Error');
      }
    };
    makeRequestsAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (event) =>
      handleClickOutside(event)
    );
    return () => {
      document.removeEventListener('mousedown', (event) =>
        handleClickOutside(event)
      );
    };
  });

  function handleClickOutside(e: MouseEvent) {
    if (!wrapperRef.current.contains(e.target) && filterOpen) {
      setFilterOpen('');
    }
  }

  return (
    <div className="w-full bg-marble lg:flex lg:pr-11">
      <div className="hidden bg-titanium py-3 pl-12 pr-6 lg:flex">
        <Icon
          path={mdiSortVariant}
          size="1.5rem"
          className="mr-2 text-topaz-500"
        />
        <span>Filters</span>
      </div>
      <button
        className="flex w-full items-center justify-between bg-titanium py-3 pl-4 pr-3 lg:hidden"
        onClick={() => {
          setFilterSectionOpen(!filterSectionOpen);
        }}
      >
        <span>Filters</span>
        <Icon
          path={filterSectionOpen ? mdiChevronUp : mdiChevronDown}
          size="1.5rem"
          className="text-topaz-500"
        />
      </button>
      <ul
        ref={wrapperRef}
        className={`w-full lg:flex ${!filterSectionOpen && 'hidden lg:block'}`}
      >
        {filters.map((filter, index) => {
          return (
            <Filter
              key={`filter-${index}`}
              label={filter.label}
              name={filter.id}
              items={filter.options}
              multiple={filter.multiple}
              open={filterOpen === filter.id}
              onClick={() => {
                setFilterOpen(filterOpen !== filter.id ? filter.id : '');
              }}
              onChange={onChange.bind(this)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default FilterBar;
