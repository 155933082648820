import { api } from './../axiosInstance';
export interface SurveyResultsStatistics {
  totalComplete: number;
  totalCompleteInLast7Days: number;
  averageCompleteTimeInSeconds: number;
  totalSurveyOpens: number;
  highestDropOffQuestionNumber: number;
  percentOnMobile?: number;
}

export interface Filters {
  year: number | undefined;
}

export const getSurveyResultsStatistics = async (
  params: Filters,
  token: string
) => {
  const res = await api.get<SurveyResultsStatistics>('SurveyResultStatistics', {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
