import React, { useEffect, useRef, useState } from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { SurveyDistributionLineChartProps } from './SurveyDistributionLineChart.model';
import { YearCheckboxs } from '../../Atoms/YearCheckboxs/YearCheckboxs';
import { SurveyCompletesByDate } from '../../../services/surveyCompletesByDate/getSurveyCompletesByDate';
import ReactEcharts from 'echarts-for-react';
import { exportEChartAsSvg } from '../../../helpers/triggerFileDownload/triggerFileDownload';
import ChartDownloadButtons from '../../Molecules/ChartDownloadButtons/ChartDownloadButtons';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

export interface LineChartData {
  date: Date;
  count: number;
}
function SurveyDistributionLineChart({
  data,
  SVGExportName,
  onCSVDownload,
}: SurveyDistributionLineChartProps) {
  const [previousYearFilter, setPreviousYearFilter] = useState<boolean>(false);
  const [thisYearFilter, setThisYearFilter] = useState<boolean>(true);
  const echartsRef = useRef<InstanceType<typeof ReactEcharts>>(null);

  interface datasets {
    data: (string | number)[][];
    color: string;
    type: string;
  }

  const [chartData, setChartData] = useState<datasets[]>([]);

  useEffect(() => {
    let datasets = [
      {
        data: data
          .filter(
            (x) =>
              x.year === surveyYear - 1 &&
              previousYearFilter &&
              (x.complete > 0 || x.abandoned > 0)
          )
          .sort((a, b) => {
            return SetSortDetails(a, b);
          })
          .map((item) => {
            return [
              new Date(item.date).toLocaleDateString('en-US'),
              item.complete,
            ];
          }),
        color: '#2B9AB3',
        type: 'line',
      },
      {
        data: data
          .filter(
            (x) =>
              x.year === surveyYear - 1 &&
              previousYearFilter &&
              (x.complete > 0 || x.abandoned > 0)
          )
          .sort((a, b) => {
            return SetSortDetails(a, b);
          })
          .map((item) => {
            return [
              new Date(item.date).toLocaleDateString('en-US'),
              item.abandoned,
            ];
          }),
        color: '#E2E4E5',
        type: 'line',
      },
      {
        data: data
          .filter(
            (x) =>
              x.year === surveyYear &&
              thisYearFilter &&
              (x.complete > 0 || x.abandoned > 0)
          )
          .sort((a, b) => {
            return SetSortDetails(a, b);
          })
          .map((item) => {
            return [
              new Date(item.date).toLocaleDateString('en-US'),
              item.complete,
            ];
          }),
        color: '#E26E00',
        type: 'line',
      },
      {
        data: data
          .filter(
            (x) =>
              x.year === surveyYear &&
              thisYearFilter &&
              (x.abandoned > 0 || x.complete > 0)
          )
          .sort((a, b) => {
            return SetSortDetails(a, b);
          })
          .map((item) => {
            return [
              new Date(item.date).toLocaleDateString('en-US'),
              item.abandoned,
            ];
          }),
        color: '#E2E4E5',
        type: 'line',
      },
    ];

    // remove empty data arrays from the dataset
    setChartData(datasets.filter((item) => item.data.length > 0));
  }, [data, previousYearFilter, thisYearFilter]);

  return (
    <div className={`'pb-0' p-4 lg:px-12 lg:pt-8`}>
      <div className=" bg-limestone py-8">
        <div className="flex w-full flex-row flex-wrap items-start justify-between">
          <div className="w-full max-w-475">
            <Heading level="h4" className="mb-2">
              Survey completes by date
            </Heading>
          </div>
          <div className="flex w-full gap-x-4 lg:w-auto">
            <ChartDownloadButtons
              onCSVDownload={onCSVDownload}
              onSVGDownload={() => {
                exportEChartAsSvg(SVGExportName || 'chart', echartsRef);
              }}
            />
          </div>
        </div>

        <YearCheckboxs
          previousYearChecked={previousYearFilter}
          thisYearChecked={thisYearFilter}
          onPreviousYearChange={(checked: boolean) => {
            setPreviousYearFilter(checked);
          }}
          onThisYearChange={(checked: boolean) => {
            setThisYearFilter(checked);
          }}
        />

        <ReactEcharts
          ref={echartsRef}
          style={{ height: '609px' }}
          opts={{ renderer: 'svg' }}
          notMerge={true}
          option={{
            tooltip: {},
            toolbox: {},
            xAxis: {
              type: 'category',
              axisLabel: {
                interval: 0,
                rotate: 70,
                show: true,
                inside: false,
              },
            },
            yAxis: {
              type: 'value',
            },
            series: chartData,
          }}
        />
      </div>
    </div>
  );
}

export default SurveyDistributionLineChart;

// Set the Sort details
const SetSortDetails = (
  a: SurveyCompletesByDate,
  b: SurveyCompletesByDate
): number => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);
  if (aDate > bDate) return 1;
  if (aDate < bDate) return -1;
  return 0;
};
