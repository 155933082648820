import React, { useCallback, useEffect, useState } from 'react';
import { FileTypes } from '../../../helpers/getFileType/getFileType';
import { Button } from '../../Atoms/Button/Button';
import { UploadIcon } from '../../Atoms/Icons/Icons';
import UploadedFileItem from '../../Atoms/UploadedFileItem/UploadedFileItem';
import Modal from '../../Molecules/Modal/Modal';
import { UploadFilesProps } from './UploadFilesModal.model';

function UploadFilesModal({
  isUploadFilesModalOpen,
  toggleUploadFilesModal,
  handleFileUploads,
  title,
}: UploadFilesProps) {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFileEvent = (e: { target: { files: any } }) => {
    const chosenFiles = Array.from<File>(e.target.files);

    setFilesToUpload([...filesToUpload, ...chosenFiles]);
  };

  const handleRemoveFile = (file: File) => {
    const filteredFiles = filesToUpload.filter(
      (fileToUpload) => fileToUpload !== file
    );
    setFilesToUpload([...filteredFiles]);
  };

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (!filesToUpload.length) {
        alert('Please select a file to upload');
        return;
      }

      setIsUploading(true);
      setIsError(false);
      handleFileUploads(filesToUpload)
        .then(() => {
          setIsUploading(false);
          toggleUploadFilesModal();
          setFilesToUpload([]);
        })
        .catch((err) => {
          setIsUploading(true);
          setIsError(true);
        });
    },
    [filesToUpload, handleFileUploads, toggleUploadFilesModal]
  );

  useEffect(() => {
    !isUploadFilesModalOpen && setFilesToUpload([]);
  }, [isUploadFilesModalOpen]);

  return (
    <Modal
      isOpen={isUploadFilesModalOpen}
      title={title}
      toggleModal={toggleUploadFilesModal}
    >
      <form onSubmit={handleSubmit}>
        <div className="border-b border-titanium pb-6">
          <div className=" flex w-full items-center justify-center ">
            <label
              htmlFor="dropzone-file"
              className="flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-md border border-titanium bg-marble hover:bg-gray-200"
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const droppedFiles = Array.from<File>(e.dataTransfer.files);
                setFilesToUpload([...filesToUpload, ...droppedFiles]);
              }}
              onDragEnd={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="flex items-center justify-center gap-4">
                <UploadIcon />
                <p className="w-32  break-words text-base md:w-auto md:text-lg">
                  Drag and drop or{' '}
                  <span className=" text-topaz-500 underline">Browse</span> to
                  upload
                </p>
              </div>
            </label>
            <input
              onChange={handleFileEvent}
              id="dropzone-file"
              type="file"
              className="sr-only absolute -top-[999px] -left-[999px]"
              multiple
              accept="application/pdf, application/vnd.ms-excel, application/msword"
            />
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-2 md:gap-6">
          {filesToUpload.map((file, index) => {
            return (
              <UploadedFileItem
                key={index}
                title={file.name}
                uploadedPercent={100}
                uploading={false}
                removeFile={() => handleRemoveFile(file)}
                fileType={('.' + file.name.split('.').pop()) as FileTypes}
              />
            );
          })}
        </div>

        {isUploading && (
          <div className="mt-2 w-full rounded-sm bg-green-500 p-2 text-center text-white">
            <p>File Upload In Progress</p>
          </div>
        )}

        {isError && (
          <div className="mt-2 w-full rounded-sm bg-red-500 p-2 text-center text-white">
            <p>File Upload Failed, Please Try Again</p>
          </div>
        )}

        <Button className="mt-4 ml-auto" type="submit">
          Upload Selected Files
        </Button>
      </form>
    </Modal>
  );
}

export default UploadFilesModal;
