// Dependencies
import { FunctionComponent } from 'react';

// Models
import { getParagraphStyle, ParagraphProps } from './Paragraph.model';

/**
 * Paragraph component
 * @param props - Component props.
 */
export const Paragraph: FunctionComponent<ParagraphProps> = ({
  children,
  variant,
  className,
}) => {
  const classNames = `${getParagraphStyle(variant)} ${className || ''}`;
  return <p className={classNames}>{children}</p>;
};
