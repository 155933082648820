import React from 'react';
import { Button } from '../../Atoms/Button/Button';
import { CloseIcon, LogoutIcon } from '../../Atoms/Icons/Icons';
import Modal from '../../Molecules/Modal/Modal';
import { LogoutModalProps } from './LogoutModal.model';
import { useAuth0 } from '@auth0/auth0-react';

function LogoutModal({
  isLogoutModalOpen,
  toggleLogoutModal,
}: LogoutModalProps) {
  const { logout } = useAuth0();

  return (
    <Modal
      isOpen={isLogoutModalOpen}
      toggleModal={toggleLogoutModal}
      title="Log out"
    >
      <p>Are you sure you wish to log out?</p>
      <div className="mt-4 flex items-center justify-center gap-4">
        <Button
          onClick={toggleLogoutModal}
          type="button"
          variant="secondary"
          className="flex items-center gap-3"
        >
          <CloseIcon fill="#1A7B99" className="h-auto w-3" />
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          className="flex items-center gap-3"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <LogoutIcon className="h-auto w-3" fill="white" />
          Logout
        </Button>
      </div>
    </Modal>
  );
}

export default LogoutModal;
