import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
export interface SurveyResultsState {
  summary: {
    institutionalTraders: number;
    tradeFX: number;
    dailyTradeFX: number;
    liquidity: number;
  };
  questions: string[];
  loading: boolean;
  error: boolean;
}
const initialState: SurveyResultsState = {
  summary: {
    institutionalTraders: 0,
    tradeFX: 0,
    dailyTradeFX: 0,
    liquidity: 0,
  },
  questions: ['test1', 'test2'],
  loading: false,
  error: false,
};

const surveyResultsSlice = createSlice({
  name: 'surveyResults',
  initialState,
  reducers: {
    updateLoading: (state) => {
      state.loading = !state.loading;
    },
    updateError: (state, action) => {},
    retrieveSummary: (state, action) => {},
    retrieveData: (state, action) => {},
  },
});

export const { retrieveData, retrieveSummary, updateError, updateLoading } =
  surveyResultsSlice.actions;
export const questionsSelector = (state: RootState) =>
  state.surveyResults.questions;
export const summarySelector = (state: RootState) =>
  state.surveyResults.summary;
export const loadingSelector = (state: RootState) =>
  state.surveyResults.loading;
export const errorSelector = (state: RootState) => state.surveyResults.error;

export default surveyResultsSlice.reducer;
