import { useState } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  return {
    isOpen,
    toggleModal,
  };
};
export default useModal;
