export type RankButtonType = 'Rank1' | 'Rank2' | 'Rank3' | '2023' | '2024';
export type RangeType = 'Rank' | 'Year';

export const rankButtons: RankButtonType[] = ['Rank1', 'Rank2', 'Rank3'];
export const rankYearButtons: RankButtonType[] = ['2023', '2024'];

export interface ChartRankButtonsProps {
  rangeType?: RangeType;
  activeChartButton?: RankButtonType;
  setActiveChartButton?: (val: RankButtonType) => void;
}
