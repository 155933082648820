import { api } from '../axiosInstance';

export interface FileEntry {
  pathAndFilename: string;
  filename: string;
  extension: string;
}

export interface FolderEntry {
  folderName: string;
  folderDisplayName: string;
  files: FileEntry[];
  folders: FolderEntry[];
}

interface FileListingProps {
  foldername: string;
  token: string;
}
export interface FileList {
  fileResults: FolderEntry[];
}

export const getFileListing = async ({
  foldername,
  token,
}: FileListingProps) => {
  const res = await api.get<FileList>('FileListing', {
    params: { foldername },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const prefixedData = res.data;

  // Add the default routes if they don't exist
  const defaultRoutes: FolderEntry[] = [
    {
      folderName: 'root/2025/',
      folderDisplayName: '2025',
      files: [],
      folders: [],
    },
    {
      folderName: 'root/2024/',
      folderDisplayName: '2024',
      files: [],
      folders: [],
    },
    {
      folderName: 'root/2023/',
      folderDisplayName: '2023',
      files: [],
      folders: [],
    },
    {
      folderName: 'root/2022/',
      folderDisplayName: '2022',
      files: [],
      folders: [],
    },
    {
      folderName: 'root/2021/',
      folderDisplayName: '2021',
      files: [],
      folders: [],
    },
  ];

  defaultRoutes.forEach((route) => {
    if (
      !prefixedData.fileResults[0].folders.find(
        (r) => r.folderName === route.folderName
      )
    ) {
      prefixedData.fileResults[0].folders.push(route);
    }
  });

  // Sort the folders
  prefixedData.fileResults[0].folders =
    prefixedData.fileResults[0].folders.sort((a, b) => {
      return +a.folderDisplayName > +b.folderDisplayName ? 1 : -1;
    });

  return prefixedData;
};
