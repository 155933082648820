import axios, { ParamsSerializerOptions } from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const paramsSerializer: ParamsSerializerOptions = {
  indexes: null,
};

export const api = axios.create({
  baseURL: BASE_URL,
  paramsSerializer,
});
