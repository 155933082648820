/* Dependencies */
import { AxiosResponse } from 'axios';
import C2S from '@mithrandirii/canvas2svg';
import { elementToSVG } from 'dom-to-svg';
import ReactEcharts from 'echarts-for-react';

// Create date for filename
export const dateForFileName = () => {
  const currentDate = new Date();
  const datePrefix = `${currentDate.getDate()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getFullYear()}`;

  return datePrefix;
};

/**
 * Trigger a file download.
 * @param response - Axios response object.
 * @param fileName - Name of file to download.
 * @param extenstion - File extension.
 */
export const triggerFileDownload = (
  response: AxiosResponse<any>,
  fileName: string
) => {
  // Extact data from response.
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], { type: type, endings: 'native' });

  // Create element to trigger download.
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${
    fileName.substring(0, fileName.lastIndexOf('.')) || fileName
  }`;
  link.click();
};

/**
 * Export file as SVG.
 * @param SVGExportName - Name of file to download.
 * @param chartRef - Instance of chart to export as SVG.
 */
export const exportChartToSvg = (SVGExportName: string, chartRef: any) => {
  if (!chartRef.current) return;

  const isCanvas = chartRef.current.canvas;
  const chart = isCanvas ? chartRef.current.canvas : chartRef.current;

  const serializeChart = (chart: any) => {
    // Serialize Table for SVG Export
    if (!isCanvas) {
      const svgDocument = elementToSVG(chart);

      return new XMLSerializer().serializeToString(svgDocument);
    }

    // Serialize Canvas for SVG Export
    const svgContext = new C2S({
      width: chart.width,
      height: chart.height,
    });
    svgContext.drawImage(chart, 0, 0, chart.width, chart.height);

    return svgContext.getSerializedSvg(true);
  };

  const downloadSVG = () => {
    const blob = new Blob([serializeChart(chart)], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `SurveyResponsesExport_${SVGExportName}_${dateForFileName()}`; // Set the download name
    a.click();
  };

  // Allow the chart to render before serialization
  setTimeout(downloadSVG, 0);
};

/**
 * Takes a ref for an echarts instance and exports the chart as an SVG.
 * @param SVGExportName
 * @param echartsRef
 */
export const exportEChartAsSvg = (
  SVGExportName: string,
  echartsRef: React.RefObject<ReactEcharts>
) => {
  if (echartsRef.current) {
    const url = echartsRef.current.getEchartsInstance().getDataURL({
      type: 'svg',
      pixelRatio: 2,
      backgroundColor: '#fff',
    });

    const a = document.createElement('a');
    a.href = url;
    a.download = SVGExportName; // Specify the file name for download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
