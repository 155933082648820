import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';
import { api } from './../axiosInstance';
export interface SurveyCompletesByCountry {
  country: string;
  sources: {
    source: string | null;
    status: string | null;
    count: number;
  }[];
}

export const getSurveyCompletesByCountry = async (
  params: SurveyFilters,
  token: string
) => {
  const res = await api.get<SurveyCompletesByCountry[]>(
    'SurveyCompletesByCountry',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
