import React from 'react';
import {
  DistributionIcon,
  FilesIcon,
  ProfileIcon,
  ResultsIcon,
  SettingsIcon,
} from '../../../Atoms/Icons/Icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import SidebarItem from '../../../Atoms/SidebarItem/SidebarItem';
import { SidebarItemsProps } from './SidebarItems.model';
import Logout from '../../Logout/Logout';
import ExportCSV from '../../ExportCSV/ExportCSV';

function SidebarItems({ mobileMenuActive, closeNav }: SidebarItemsProps) {
  const router = useLocation();
  const { user } = useAuth0();
  const navLinks = [
    {
      title: 'Survey Results',
      to: '/survey-results',
      icon: (
        <ResultsIcon
          className="w-7"
          active={router.pathname === '/survey-results'}
        />
      ),
    },
    {
      title: 'Survey Distribution',
      to: '/survey-distribution',
      icon: (
        <DistributionIcon
          className="w-7"
          active={router.pathname === '/survey-distribution'}
        />
      ),
    },
    {
      title: 'Files',
      to: '/files',
      icon: <FilesIcon className="w-7" active={router.pathname === '/files'} />,
    },
  ];
  return (
    <div
      className={`${
        mobileMenuActive
          ? 'flex h-full w-full bg-white pt-8 lg:px-4 lg:pt-0'
          : 'hidden'
      } min-h-[calc(100vh-130px)] flex-col justify-between lg:flex`}
    >
      <ul className="lg:mt-10">
        {navLinks.map((link) => (
          <SidebarItem
            key={link.to}
            to={link.to}
            icon={link.icon}
            title={link.title}
            variant="link"
            onClick={closeNav}
          />
        ))}
        <ExportCSV />
      </ul>
      <ul className="mt-8">
        <SidebarItem
          icon={<ProfileIcon className="w-7" />}
          title={`${user?.name}`}
          variant="label"
        />
        <SidebarItem
          to="/my-account"
          onClick={closeNav}
          icon={
            <SettingsIcon
              className="w-7"
              active={router.pathname === '/my-account'}
            />
          }
          title="Account Settings"
          variant="link"
        />
        <Logout />
      </ul>
    </div>
  );
}

export default SidebarItems;
