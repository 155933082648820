import axios from 'axios';

const BASE_URL = process.env.REACT_APP_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_AUTH_0_CLIENT_ID;

export async function triggerPasswordChangeFlow(
  email: string
): Promise<boolean> {
  try {
    let options = {
      method: 'POST',
      url: 'https://' + BASE_URL + '/dbconnections/change_password',
      headers: { 'Content-Type': 'application/json' },
      data: {
        client_id: CLIENT_ID,
        email: email,
        connection: 'Username-Password-Authentication',
      },
    };

    let res = await axios.request(options);

    return res.status === 200;
  } catch {
    return false;
  }
}
