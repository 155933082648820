import { api } from '../axiosInstance';

interface FileUploadProps {
  UploadFile: File;
  FolderName: string;
  token: string;
}

export const fileUpload = async ({
  FolderName,
  UploadFile,
  token,
}: FileUploadProps) => {
  const formData = new FormData();
  if (UploadFile) {
    formData.append('UploadFile', UploadFile);
    formData.append('FolderName', FolderName);
  }
  const res = await api.post('FileUpload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
