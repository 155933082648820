export interface GetDescribedByProps {
    error?: string;
    id: string;
    name: string;
    supportText?: string;
    toolTip?: string;
  }
  
  /**
   * Sets the described by values for the field.
   * @param props - The props to be used.
   * @returns
   */
  export const getDescribedBy = (props: GetDescribedByProps): string => {
    let describedBy: string = '';
    if (props.toolTip) {
      describedBy += `${props.id}_${props.name}-tooltip`;
    }
  
    if (props.error || props.supportText) {
      describedBy += `${props.id}_${props.name}-help`;
    }
  
    return describedBy;
  };
  