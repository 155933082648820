import React from 'react';
import { getFileType } from '../../../helpers/getFileType/getFileType';
import { CloseIcon, RemoveIcon } from '../Icons/Icons';
import { UplaodedFileItemProps } from './UploadedFileItem.model';

function UploadedFileItem({
  fileType,
  uploading,
  uploadedPercent,
  title,
  removeFile,
}: UplaodedFileItemProps) {
  return (
    <div className="flex h-20 w-full flex-col bg-marble px-4 py-3 md:px-5 md:py-4">
      <div className="flex gap-4">
        <div className="h-auto w-10">{getFileType(fileType)}</div>
        <div className="flex w-full items-center justify-between">
          <div className="flex h-full w-full flex-col justify-between text-sm md:text-lg">
            <div className="flex justify-between  font-medium ">
              {uploading ? (
                <p>
                  Uploading {title}... {uploadedPercent}%
                </p>
              ) : (
                <p> {title} </p>
              )}
              <div>
                {uploading && (
                  <button>
                    <CloseIcon />
                  </button>
                )}
              </div>
            </div>

            <div>
              {uploading ? (
                <div className="h-2.5 w-full rounded-full bg-gray-200">
                  <div
                    style={{ width: `${uploadedPercent}%` }}
                    className="h-2.5 rounded-full bg-topaz-500"
                  ></div>
                </div>
              ) : (
                'Ready to upload'
              )}
            </div>
          </div>{' '}
          {!uploading && (
            <button type="button" onClick={removeFile}>
              <RemoveIcon className="h-auto w-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadedFileItem;
