import { configureStore } from '@reduxjs/toolkit';
import appReducer from './features/appSlice/appSlice';
import surveyResultsReducer from './features/surveyResultsSlice/surveyResultsSlice';
import filesReducer from './features/filesSlice/filesSlice';
import surveyDistributionReducer from './features/surveyDistributionSlice/surveyDistributionSlice';
const store = configureStore({
  reducer: {
    app: appReducer,
    surveyResults: surveyResultsReducer,
    surveyDistribution: surveyDistributionReducer,
    files: filesReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
