import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect, useCallback } from 'react';
import { FileTypes } from '../../../helpers/getFileType/getFileType';
import { triggerFileDownload } from '../../../helpers/triggerFileDownload/triggerFileDownload';
import { fileDelete } from '../../../services/fileApis/fileDelete';
import { fileDownload } from '../../../services/fileApis/fileDownload';
import {
  FileEntry,
  FolderEntry,
  getFileListing,
} from '../../../services/fileApis/fileListing';
import { fileUpload } from '../../../services/fileApis/fileUpload';
import { Button } from '../../Atoms/Button/Button';
import FileItem from '../../Atoms/FileItem/FileItem';
import PageContainer from '../../Containers/PageContainer/PageContainer';
import useModal from '../../hooks/useModal';
import UploadFilesModal from '../../Organisms/UploadFilesModal/UploadFilesModal';
function Files() {
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, toggleModal } = useModal();
  const [fileList, setFileList] = useState<FolderEntry[]>();
  const [fileCount, setFileCount] = useState<number>(0);
  const [uploadFolderPath, setUploadFolderPath] = useState<string>('root');

  const loadFileListing = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: '',
      });

      getFileListing({ foldername: 'root', token: accessToken }).then((res) => {
        if (!res.fileResults.length) {
          setFileList([]);
          setFileCount(0);
          return;
        }

        setFileList(res.fileResults[0].folders.reverse());
        setFileCount(
          res.fileResults[0].folders.reduce((prevFolder, currentFolder) => {
            return prevFolder + currentFolder.files.length;
          }, 0)
        );
      });
    } catch (e) {
      alert('Authentication Error');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    loadFileListing();
  }, [loadFileListing]);

  const handleFileUploads = useCallback(
    async (files: File[]) => {
      return new Promise(async (resolve, reject) => {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        try {
          for (const file of files) {
            await fileUpload({
              UploadFile: file,
              FolderName: uploadFolderPath
                .split('/')
                .filter((item) => item)
                .join('/'),
              token: accessToken,
            });
          }

          loadFileListing();

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    [uploadFolderPath, getAccessTokenSilently, loadFileListing]
  );

  const handleDeleteFile = useCallback(
    async (fileEntry: FileEntry) => {
      return new Promise(async (resolve, reject) => {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        try {
          await fileDelete({
            pathAndFilename: fileEntry.pathAndFilename,
            token: accessToken,
          });

          loadFileListing();

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    [getAccessTokenSilently, loadFileListing]
  );

  const handleFileDownload = useCallback(
    async (fileEntry: FileEntry) => {
      return new Promise(async (resolve, reject) => {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        try {
          const response = await fileDownload({
            pathAndFilename: fileEntry.pathAndFilename,
            token: accessToken,
          });

          triggerFileDownload(response, fileEntry.filename);

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    [getAccessTokenSilently]
  );

  return (
    <PageContainer
      bgColor="bg-marble"
      title={`Files (${fileCount})`}
      button={null}
    >
      <div className="divide-y-1 grid grid-cols-1 gap-8 p-4 lg:px-12 lg:py-8">
        {fileList?.map((item, flIndex) => {
          return (
            <div className="" key={flIndex}>
              <div className="flex w-full flex-row flex-wrap items-center">
                <h2 className="text-xl text-brown-500">
                  {item.folderDisplayName}
                </h2>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    setUploadFolderPath(item.folderName);
                    toggleModal();
                  }}
                  className="ml-auto uppercase"
                >
                  Upload
                </Button>
              </div>

              <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                {item.files.map((item, fIndex) => {
                  return (
                    <FileItem
                      key={fIndex}
                      originalFile={item}
                      fileTitle={item.filename}
                      fileType={item.extension as FileTypes}
                      handleFileDownload={(file) => {
                        handleFileDownload(file);
                      }}
                      handleFileDelete={(file) => {
                        handleDeleteFile(file);
                      }}
                    />
                  );
                })}
              </div>

              <hr className="mt-8" />
            </div>
          );
        })}
      </div>

      <UploadFilesModal
        isUploadFilesModalOpen={isOpen}
        title="Upload Files"
        toggleUploadFilesModal={toggleModal}
        handleFileUploads={handleFileUploads}
      />
    </PageContainer>
  );
}

export default Files;
