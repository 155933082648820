import React from 'react';
import { Link } from 'react-router-dom';
import { SidebarItemProps } from './SidebarItem.model';
import { useLocation } from 'react-router-dom';
import { RightArrow } from '../Icons/Icons';

function SidebarItem({
  to,
  title,
  icon,
  variant,
  activeNavButton,
  onClick,
}: SidebarItemProps) {
  const location = useLocation();
  return (
    <div>
      <li
        className={`border-b border-charcoal ${
          variant !== 'label' ? 'hover:bg-limestone hover:fill-topaz-500' : ''
        } `}
      >
        {variant === 'link' && (
          <Link
            onClick={onClick}
            to={to as string}
            className="flex items-center gap-3 py-3 pr-3  pl-1"
          >
            {icon}
            {title}
            {location.pathname === to && <RightArrow className="ml-auto" />}
          </Link>
        )}

        {variant === 'button' && (
          <button
            onClick={onClick}
            className="flex w-full items-center gap-3 py-3 pr-3  pl-1"
          >
            {icon}
            {title}
            {activeNavButton && <RightArrow className="ml-auto" />}
          </button>
        )}

        {variant === 'label' && (
          <label className="flex w-full items-center gap-3 py-3 pr-3  pl-1">
            {icon}
            {title}
            {activeNavButton && <RightArrow className="ml-auto" />}
          </label>
        )}
      </li>
    </div>
  );
}

export default SidebarItem;
