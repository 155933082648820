import React from 'react';
import { SummaryResultsItemProps } from './SummaryResultsItem.model';

function SummaryResultsItem({
  className,
  description,
  result,
  suffix,
  dataInverted = false,
}: SummaryResultsItemProps) {
  return (
    <div
      className={`summary-results-item relative py-4 px-5 lg:m-4 ${
        className || ''
      }`}
    >
      <div className="flex h-full flex-col leading-tight">
        <p className="text-sm lg:text-base">{description}</p>
        <p className="summary-results-item-result flex flex-1 items-center font-celeste text-[50px] text-topaz-500 lg:text-[80px] xl:text-[100px] 2xl:text-[110px]">
          {dataInverted ? (
            <>
              <span className="text-[35px] xl:text-4xl">{suffix}</span> {result}
            </>
          ) : (
            <>
              {result} <span className="text-[35px] xl:text-4xl">{suffix}</span>
            </>
          )}
        </p>
      </div>
    </div>
  );
}

export default SummaryResultsItem;
