// Dependencies
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

// Components
import { CheckboxList } from '../../Molecules/Forms/CheckboxList/CheckboxList';

// Models
import { FilterProps } from './Filter.model';
import { FilterOption } from '../../Organisms/FilterBar/FilterBar.model';
import { RadioList } from '../Forms/RadioList/RadioList';

export const Filter: React.FC<FilterProps> = ({
  label,
  name,
  items,
  multiple = false,
  open,
  onClick,
  onChange,
}) => {
  const [isCheckAll, setIsCheckAll] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState<string[]>([]);
  const [isSelected, setIsSelected] = useState<string>('all');
  const [filters, setFilters] = useState<FilterOption[]>([]);

  useEffect(() => {
    setFilters(items);
    setIsChecked(items.map((item) => item.value));
  }, [items]);

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll(!isCheckAll);
    let updatedIsChecked = filters.map((filter) => filter.value);
    if (isCheckAll) {
      updatedIsChecked = [];
    }

    setIsChecked(updatedIsChecked);
    onChange({ id: e.target.name, filters: updatedIsChecked });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    let updatedIsChecked = [...isChecked, value];
    if (!checked) {
      updatedIsChecked = isChecked.filter((item) => item !== value);
      setIsCheckAll(false);
    } else if (
      !filters.find((item) => !updatedIsChecked.includes(item.value))
    ) {
      setIsCheckAll(true);
    }

    setIsChecked(updatedIsChecked);
    onChange({ id: e.target.name, filters: updatedIsChecked });
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (!checked) return;

    setIsSelected(value);
    onChange({ id: e.target.name, filter: value });
  };

  return (
    <li
      className={`relative w-full basis-60 pr-3 lg:w-auto lg:border-x lg:border-t ${
        open ? 'lg:border-topaz-500' : 'lg:border-transparent'
      } lg:py-3`}
    >
      <button
        className="flex w-full justify-between py-3 pl-4 lg:ml-2 lg:py-0"
        onClick={onClick.bind(this)}
      >
        <span>{label}</span>
        <Icon
          path={open ? mdiChevronUp : mdiChevronDown}
          size="1.5rem"
          className="text-topaz-500"
        />
      </button>
      <div
        className={`bg-marble py-2.5 px-2 lg:absolute lg:-left-px lg:-right-px lg:mt-3 lg:border-x lg:border-b lg:border-topaz-500 ${
          !open && 'hidden'
        }`}
      >
        {multiple && (
          <CheckboxList
            items={[
              {
                label: 'All',
                name,
                onChange: handleCheckAll.bind(this),
                onBlur: () => {},
                value: 'all',
                checked: isCheckAll,
              },
              ...filters.map((item) => {
                return {
                  label: item.label || item.value,
                  name,
                  onChange: handleCheck.bind(this),
                  onBlur: () => {},
                  value: item.value,
                  checked: isChecked.includes(item.value),
                };
              }),
            ]}
          />
        )}

        {!multiple && (
          <RadioList
            items={[
              {
                label: 'All',
                name,
                onChange: handleSelect.bind(this),
                onBlur: () => {},
                value: 'all',
                selected: isSelected === 'all',
              },
              ...filters.map((item) => {
                return {
                  label: item.label || item.value,
                  name,
                  onChange: handleSelect.bind(this),
                  onBlur: () => {},
                  value: item.value,
                  selected: isSelected === item.value,
                };
              }),
            ]}
          />
        )}
      </div>
    </li>
  );
};
