import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
export const LoginRoute = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isAuthenticated) return <Navigate to="/survey-results" />;
  return <Outlet />;
};
