import React from 'react';
import EditUser from '../../Atoms/EditUser/EditUser';
import PageContainer from '../../Containers/PageContainer/PageContainer';

function AccountSettings() {
  return (
    <PageContainer bgColor="bg-marble" button={false} title="Account Settings">
      <div className="divide-y-1 grid grid-cols-1 gap-8 p-4 lg:px-12 lg:py-8">
        <EditUser />
      </div>
    </PageContainer>
  );
}

export default AccountSettings;
