import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import JPMMainLogo from '../../Atoms/JPMLogo/JPMMainLogo';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex h-screen w-full items-center justify-center bg-limestone px-4">
      <div className="w-full max-w-md rounded-md border border-titanium bg-white px-8 py-6 pb-8">
        <JPMMainLogo topBorder={false} />
        <Heading className="mb-2 mt-6 text-brown-500" level="h3">
          Welcome
        </Heading>
        <Paragraph className="border-b border-titanium pb-6">
          Log In to the <strong>e-Trading dashboard</strong>
        </Paragraph>
        <Button
          onClick={() => loginWithRedirect()}
          variant="primary"
          className="mt-6 w-full"
          type="button"
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default Login;
