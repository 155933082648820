import React, { memo } from 'react';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

interface ChartHeadingProps {
  chartTitle?: string;
  chartDescription?: string;
}

const ChartHeading = memo(
  ({ chartTitle, chartDescription }: ChartHeadingProps) => {
    return (
      <div className="w-full max-w-lg">
        <Heading level="h4" className="mb-2 xl:mb-4">
          {chartTitle}
        </Heading>
        <Paragraph variant="sm" className="mb-4 xl:mb-2">
          {chartDescription}
        </Paragraph>
      </div>
    );
  }
);

export default ChartHeading;
