export type Variant = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export interface ParagraphProps extends React.PropsWithChildren {
  className?: string;
  variant?: Variant;
}

/**
 * Get the style associated with the paragraph.
 * @param variant - The variant of the paragraph.
 * @returns
 */
export const getParagraphStyle = (variant?: Variant): string => {
  switch (variant) {
    case 'xs': {
      return 'text-xs';
    }
    case 'sm': {
      return 'text-sm';
    }
    case 'base': {
      return 'text-base';
    }
    case 'lg': {
      return 'text-base lg:text-lg';
    }
    case 'xl': {
      return 'text-lg lg:text-xl';
    }
    default: {
      return 'text-lg';
    }
  }
};
