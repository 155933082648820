import { api } from '../axiosInstance';

interface FileDownloadProps {
  pathAndFilename: string;
  token: string;
}

export const fileDownload = async ({
  pathAndFilename,
  token,
}: FileDownloadProps) => {
  const res = await api.get<Blob>('FileDownload', {
    params: {
      pathAndFilename,
    },
    responseType: 'blob',
    headers: {
      'Cache-Control': 'max-age=0',
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept:
        'application/pdf, application/vnd.ms-excel, pplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
