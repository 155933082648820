import React, { memo } from 'react';
import { Button } from '../../Atoms/Button/Button';

interface ChartDownloadButtonsProps {
  onCSVDownload?: () => void;
  onSVGDownload?: () => void;
  onXLSDownload?: () => void;
}

const ChartDownloadButtons = memo(
  ({
    onCSVDownload,
    onSVGDownload,
    onXLSDownload,
  }: ChartDownloadButtonsProps) => {
    return (
      <div className="flex w-80 gap-x-4 lg:w-80">
        {onSVGDownload && (
          <Button
            variant="secondary"
            className="mb-6 w-1/2 lg:w-auto xl:mb-0"
            type="button"
            onClick={onSVGDownload}
          >
            Download SVG
          </Button>
        )}
        {onCSVDownload && (
          <Button
            variant="standard"
            className="mb-6 w-1/2 lg:w-auto xl:mb-0"
            type="button"
            onClick={onCSVDownload}
          >
            Download CSV
          </Button>
        )}
        {onXLSDownload && (
          <Button
            variant="standard"
            className="mb-6 w-1/2 lg:w-auto xl:mb-0"
            type="button"
            onClick={onXLSDownload}
          >
            Download XLS
          </Button>
        )}
      </div>
    );
  }
);

export default ChartDownloadButtons;
