import { api } from '../axiosInstance';
import { SurveyFilters } from '../getSurveyFilters/SurveyFilters.model';

type QuestionIdType =
  | 'InflationChangePercentage'
  | 'PercentageETradingThisYear'
  | 'PercentageETradingNextYear'
  | 'GreatestTradingChallenge'
  | 'GreatestDevelopmentImpactRank1'
  | 'GreatestDevelopmentImpactRank2'
  | 'GreatestDevelopmentImpactRank3'
  | 'MostInfluentialTechnologyRank1'
  | 'MostInfluentialTechnologyRank2'
  | 'MostInfluentialTechnologyRank3'
  | 'TopMarketConcernsRank1'
  | 'TopMarketConcernsRank2'
  | 'TopMarketConcernsRank3'
  | 'Inflation'
  | 'CryptoTradingStatus'
  | 'CryptoTradingChange'
  | 'CurrentTradedProducts'
  | 'TraderServiceLength'
  | 'ProductDevelopmentRank1'
  | 'ProductDevelopmentRank2'
  | 'ProductDevelopmentRank3'
  | 'YourAge'
  | 'Age'
  | 'ResidentCountry';

export interface QuestionResponse {
  answer: string;
  count: number;
  average?: number;
  questionId: QuestionIdType;
  year: number;
}

export const getQuestionResponses = async (
  params: SurveyFilters,
  token: string
) => {
  const res = await api.get('QuestionResponses', {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
