// Dependencies
import React from 'react';

// Components
import { Radio } from '../Radio/Radio';

// Models
import { RadioListProps } from './RadioList.model';

/**
 * Radio List Component
 */
export const RadioList: React.FC<RadioListProps> = ({ items, twoColumn }) => {
  return (
    <div className={`${twoColumn && "columns-2"}`}>
      {items.map((item, index) => {
        return (
          <div className="mb-2 block w-full" key={index}>
            <Radio {...item} />
          </div>
        );
      })}
    </div>
  );
};
