import NoMatchRoute from '../../../Views/NoMatchRoute/NoMatchRoute';
import AccountSettings from '../../../Views/AccountSettings/AccountSettings';
import Files from '../../../Views/Files/Files';
import SurveyDistribution from '../../../Views/SurveyDistribution/SurveyDistribution';
import SurveyResults from '../../../Views/SurveyResults/SurveyResults';

interface IRoute {
  path: string;
  element: () => JSX.Element;
}

export const routeslist: IRoute[] = [
  {
    path: '/survey-results',
    element: SurveyResults,
  },
  {
    path: '/survey-distribution',
    element: SurveyDistribution,
  },

  {
    path: '/files',
    element: Files,
  },
  {
    path: '/my-account',
    element: AccountSettings,
  },
  {
    path: '*',
    element: NoMatchRoute,
  },
];
