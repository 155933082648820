export type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type LevelExtended = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'sh3';

export interface HeadingProps extends React.PropsWithChildren {
  className?: string;
  level: Level;
  textStyle?: keyof typeof Styles;
}

export const Styles = Object.freeze({
  'Heading 1': 'h1',
  'Heading 2': 'h2',
  'Heading 3': 'h3',
  'Heading 4': 'h4',
  'Heading 5': 'h5',
  'Sub Heading 3': 'sh3',
});

/**
 * Get the style associated with the heading.
 * @param level - The level of the heading.
 * @returns
 */
export const getHeadingStyle = (level: LevelExtended): string => {
  switch (level) {
    case 'h1': {
      return 'text-3xl lg:text-4xl font-light';
    }
    case 'h2': {
      return 'text-2xl lg:text-3xl font-light';
    }
    case 'h3': {
      return 'text-xl lg:text-2xl font-light';
    }
    case 'h4': {
      return 'text-base lg:text-xl font-light';
    }
    case 'h5':
    case 'h6': {
      return 'text-lg font-light';
    }
    case 'sh3': {
      return 'text-base';
    }
    default: {
      return '';
    }
  }
};
