// Dependencies
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { RadioProps } from './Radio.model';

/**
 * Input Component
 */
export const Radio: React.FC<RadioProps> = ({
  disabled,
  label,
  name,
  onChange,
  onBlur,
  readOnly,
  required,
  selected,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ id, name }));
  }, [id, name]);

  // Render the component.
  return (
    <div className="group relative">
      <input
        id={id}
        type="radio"
        name={name}
        onChange={onChange.bind(this)}
        onBlur={onBlur.bind(this)}
        aria-describedby={describedBy}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        defaultChecked={selected}
        className="peer absolute top-0 right-0 bottom-0 left-0 m-0 cursor-pointer appearance-none"
      />
      <label htmlFor={id} className="flex items-center justify-start">
        <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-titanium bg-white group-hover:peer-enabled:[label_&]:border-green-600 peer-enabled:[label_&]:group-hover:bg-marble peer-enabled:[label_&]:peer-checked:[label_&]:border-green-500 peer-enabled:[label_&]:peer-checked:[label_&]:bg-marble">
          <div className="hidden h-4 w-4 rounded-full bg-green-500 peer-checked:[label_&]:block peer-disabled:[label_&]:bg-titanium"></div>
        </div>
        <div className="ml-2 break-all">{label}</div>
      </label>
    </div>
  );
};
