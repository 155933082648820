import {
  CSVIcon,
  DocFileIcon,
  ExcelFileIcon,
  PDFFileIcon,
  MP4Icon,
} from '../../components/Atoms/Icons/Icons';
export type FileTypes = '.pptx' | '.csv' | '.xlsx' | '.pdf' | '.doc' | '.mp4';
export const getFileType = (type?: FileTypes) => {
  if (type === '.doc') {
    return <DocFileIcon />;
  }
  if (type === '.pdf') {
    return <PDFFileIcon />;
  }
  if (type === '.xlsx') {
    return <ExcelFileIcon />;
  }
  if (type === '.csv') {
    return <CSVIcon />;
  }
  if (type === '.mp4') {
    return <MP4Icon />;
  }
};
