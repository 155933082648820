// Dependencies
import { FunctionComponent } from 'react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

// Models
import { ButtonProps, disabledVariants, variants } from './Button.model';

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  to = '',
  arrowDirection = 'None',
  variant = 'primary',
  type = 'button',
  disabled = false,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`flex items-center justify-center text-base leading-4 ${
        disabled ? disabledVariants[variant] : variants[variant]
      } ${className ? className : ''}`}
      disabled={disabled}
    >
      {arrowDirection === 'Left' && (
        <Icon path={mdiArrowLeft} size="1rem" className="mr-2" />
      )}
      {children}
      {arrowDirection === 'Right' && (
        <Icon path={mdiArrowRight} size="1rem" className="ml-2" />
      )}
    </button>
  );
};
