import { api } from '../axiosInstance';

interface FileDeleteProps {
  pathAndFilename: string;
  token: string;
}

export const fileDelete = async ({
  pathAndFilename,
  token,
}: FileDeleteProps) => {
  const res = await api.get('FileDelete', {
    params: {
      pathAndFilename,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
