import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
interface SurveyDistributionState {
  summary: {
    numberOfCompletes: number;
    dataCollectionStatus: 'ACTIVE' | 'INACTIVE';
    responsesThisWeek: number;
    averageTimeToComplete: number;
    surveyOpens: number;
    numberOfDropOffs: number;
    dropOffRate: number;
    qHighestDropOffRate: number;
  };
  channels: number[];
  date: Date[];
  country: string[];
  loading: boolean;
  error: boolean;
}
const initialState: SurveyDistributionState = {
  summary: {
    numberOfCompletes: 0,
    dataCollectionStatus: 'ACTIVE',
    responsesThisWeek: 0,
    averageTimeToComplete: 0,
    surveyOpens: 0,
    numberOfDropOffs: 0,
    dropOffRate: 0,
    qHighestDropOffRate: 0,
  },
  channels: [],
  date: [],
  country: [],
  loading: false,
  error: false,
};

const surveyDistributionSlice = createSlice({
  name: 'surveyDistribution',
  initialState,
  reducers: {
    updateLoading: (state, action) => {},
    updateError: (state, action) => {},
    retrieveSummary: (state, action) => {},
    retrieveData: (state, action) => {},
  },
});

export const { retrieveData, retrieveSummary, updateError, updateLoading } =
  surveyDistributionSlice.actions;
export const channelsSelector = (state: RootState) =>
  state.surveyDistribution.channels;
export const dateSelector = (state: RootState) => state.surveyDistribution.date;
export const countrySelector = (state: RootState) =>
  state.surveyDistribution.country;
export const summarySelector = (state: RootState) =>
  state.surveyDistribution.summary;
export const loadingSelector = (state: RootState) =>
  state.surveyDistribution.loading;
export const errorSelector = (state: RootState) =>
  state.surveyDistribution.error;

export default surveyDistributionSlice.reducer;
