import React from 'react';
import { getFileType } from '../../../helpers/getFileType/getFileType';
import useModal from '../../hooks/useModal';
import RemoveModal from '../../Organisms/RemoveModal/RemoveModal';
import { Button } from '../Button/Button';
import { CSVEXportIcon, RemoveIcon } from '../Icons/Icons';
import { FileItemProps } from './FileItem.model';

function FileItem({
  fileType,
  fileTitle,
  originalFile,
  handleFileDownload,
  handleFileDelete,
}: FileItemProps) {
  const { isOpen, toggleModal } = useModal();

  return (
    <div
      className="relative flex min-h-[100px] cursor-pointer flex-col items-center justify-between rounded-md border border-titanium bg-white py-10 px-8 md:min-h-[152px]"
      onClick={() => {
        handleFileDownload(originalFile);
      }}
    >
      <button
        className="absolute right-1 top-2  md:right-3 md:top-4"
        onClick={(e) => {
          e.stopPropagation();
          toggleModal();
        }}
      >
        <RemoveIcon className="w-4" />
      </button>
      <div className="mb-2 h-auto w-auto min-w-[56px] md:mb-4">
        {getFileType(fileType)}
      </div>
      <p className="mb-2 text-center md:mb-4">{fileTitle}</p>
      <Button
        type="button"
        variant="secondary"
        className="flex items-center gap-3"
      >
        <CSVEXportIcon fill="#1A7B99" className="w-6" />
        Download
      </Button>

      <RemoveModal
        description="Are you sure you want to delete this file? "
        title="Delete File"
        isRemoveModalOpen={isOpen}
        toggleRemoveModal={toggleModal}
        onRemoveSubmit={() => {
          handleFileDelete(originalFile);
        }}
      />
    </div>
  );
}

export default FileItem;
