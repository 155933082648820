export interface SurveyFilters {
  source: string[] | undefined;
  country: string[] | undefined;
  age: string[] | undefined;
  tradedProducts: string[] | undefined;
  year: number | undefined;
}

export const handleFilterChange =
  (
    callback: (value: React.SetStateAction<SurveyFilters>) => void,
    selectedFilters: SurveyFilters
  ) =>
  ({
    id,
    filter,
    filters,
  }: {
    id: string;
    filter?: string;
    filters?: string[];
  }) => {
    const newFilters = { ...selectedFilters };

    switch (id) {
      case 'source':
        newFilters.source = filters;
        break;
      case 'country':
        newFilters.country = filters;
        break;
      case 'age':
        newFilters.age = filters;
        break;
      case 'product-traded':
        newFilters.tradedProducts = filters;
        break;
    }

    callback(newFilters);
  };
