import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
interface UserState {
  currentUser: {
    name: string;
    email: string;
    referenceId: string;
  };
  currentPage: number;
  availableSteps: number;
}
const initialState: UserState = {
  currentUser: {
    name: '',
    email: '',
    referenceId: '',
  },
  availableSteps: 0,
  currentPage: 1,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateNextStep: (state, action) => {},
    setUserReference: (state, action) => {},
  },
});

export const referenceIdSelector = (state: RootState) =>
  state.app.currentUser.referenceId;
export const availableStepsSelector = (state: RootState) =>
  state.app.availableSteps;
export const currentPageSelector = (state: RootState) => state.app.currentPage;

export const { setUserReference, updateNextStep } = appSlice.actions;

export default appSlice.reducer;
