// function to accept string array and iterate that array to return a new array with each element split into chunks
export function formatLabels(items: string[]) : string[] {

    let answer = [];
    for (let i = 0; i < items.length; i++) {
      let array = items[i].split(' ');
      let thisString = "";
      let thisSpace = "";

      for (let i = 0; i < array.length; i=i+1) {
        if (i % 2 === 0) {
          thisString = thisString + thisSpace + array[i] + '\n';
        } else {
          thisString = thisString + thisSpace + array[i]; 
        }
        thisSpace=" ";
      }
      answer.push(thisString);
    }
  
    return answer;
}