import { api } from '../axiosInstance';

export const getSurveyFilters = async (token: string) => {
  const res = await api.get('SurveyFilters', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
