// Dependencies
import { FunctionComponent } from 'react';
import { Checkbox } from '../../Molecules/Forms/Checkbox/Checkbox';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

// Models
export interface IYearCheckboxsProps {
  previousYearChecked: boolean;
  thisYearChecked: boolean;
  previousYearEnabled?: boolean;
  thisYearEnabled?: boolean;
  onPreviousYearChange?: (checked: boolean) => void;
  onThisYearChange?: (checked: boolean) => void;
}

export const YearCheckboxs: FunctionComponent<IYearCheckboxsProps> = ({
  previousYearChecked,
  thisYearChecked,
  previousYearEnabled = true,
  thisYearEnabled = true,
  onPreviousYearChange,
  onThisYearChange,
}) => {
  return (
    <div className="flex w-full flex-col items-end justify-center pt-6 pb-2 lg:pt-3">
      <div className={`columns-2`}>
        <div className={`block w-full`}>
          <Checkbox
            label={(surveyYear - 1).toString()}
            name="previousYear"
            labelClasses={[
              `text-white px-1 ${
                previousYearEnabled ? 'bg-topaz-400' : 'bg-titanium'
              }`,
            ]}
            onChange={() => {
              onPreviousYearChange &&
                onPreviousYearChange(!previousYearChecked);
            }}
            value={(surveyYear - 1).toString()}
            checked={previousYearChecked}
            onBlur={() => {}}
            disabled={!previousYearEnabled || !thisYearChecked}
          />
        </div>
        <div className={`mt-3 block w-full`}>
          <Checkbox
            label={surveyYear.toString()}
            name={surveyYear.toString()}
            labelClasses={[
              `text-white px-1 ${
                thisYearEnabled ? 'bg-clementine-400' : 'bg-titanium'
              }`,
            ]}
            onChange={() => {
              onThisYearChange && onThisYearChange(!thisYearChecked);
            }}
            value={surveyYear.toString()}
            checked={thisYearChecked}
            onBlur={() => {}}
            disabled={!thisYearEnabled || !previousYearChecked}
          />
        </div>
      </div>
    </div>
  );
};
