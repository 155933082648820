import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { LayoutProps } from './Layout.model';
import { useLocation } from 'react-router-dom';
function Layout({ children }: LayoutProps) {
  const location = useLocation();
  return (
    <div className="overflow-inherit flex min-h-screen flex-col scrollbar-thin scrollbar-thumb-topaz-500 lg:flex-row lg:flex-wrap">
      {location.pathname !== '/' && <Sidebar />}
      <main
        className={
          location.pathname !== '/'
            ? 'w-full lg:max-w-[calc(100%-300px)]'
            : 'w-full'
        }
      >
        {children}
      </main>
    </div>
  );
}

export default Layout;
