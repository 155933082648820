import React from 'react';
import { ExportIcon } from '../../Atoms/Icons/Icons';
import SidebarItem from '../../Atoms/SidebarItem/SidebarItem';
import useModal from '../../hooks/useModal';
import ExportCSVModal from '../ExportCSVModal/ExportCSVModal';

function ExportCSV() {
  const { isOpen, toggleModal } = useModal();
  return (
    <>
      <SidebarItem
        icon={<ExportIcon className="w-7" active={isOpen} />}
        title="Export CSV"
        variant="button"
        onClick={toggleModal}
      />
      <ExportCSVModal
        isExportCSVModalOpen={isOpen}
        toggleExportCSVModal={toggleModal}
      />
    </>
  );
}

export default ExportCSV;
